<script>
import { Line } from "vue-chartjs";
import Chart from "chart.js";

const flagSvg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="5" fill="#36B67D"/>
<path d="M18.5 7.50001V15C18.4996 15.0712 18.484 15.1415 18.4543 15.2061C18.4245 15.2708 18.3813 15.3284 18.3275 15.375C17.3725 16.2019 16.4594 16.5 15.5887 16.5C14.4069 16.5 13.3031 15.9538 12.2762 15.4469C10.6169 14.625 9.17438 13.9131 7.5 15.2356V18C7.5 18.1326 7.44732 18.2598 7.35355 18.3536C7.25979 18.4473 7.13261 18.5 7 18.5C6.86739 18.5 6.74021 18.4473 6.64645 18.3536C6.55268 18.2598 6.5 18.1326 6.5 18V7.50001C6.50048 7.42878 6.51617 7.35847 6.54603 7.2938C6.57589 7.22913 6.61922 7.17158 6.67313 7.12501C8.92313 5.17626 10.9425 6.17439 12.7225 7.05501C14.4375 7.90376 15.9244 8.63751 17.6725 7.12501C17.7448 7.06238 17.8335 7.02178 17.9281 7.00802C18.0227 6.99427 18.1193 7.00793 18.2064 7.0474C18.2935 7.08687 18.3674 7.15049 18.4195 7.23071C18.4715 7.31093 18.4995 7.40439 18.5 7.50001Z" fill="white"/>
</svg>`;

const hashureSvg = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_5712_931)">
    <rect width="24" height="20" fill="#B23066" />
    <path d="M-1 -1L15 21" stroke="white" stroke-opacity="0.6" stroke-width="2" />
    <path d="M-9 -1L7 21" stroke="white" stroke-opacity="0.6" stroke-width="2" />
    <path d="M7 -1L23 21" stroke="white" stroke-opacity="0.6" stroke-width="2" />
    <path d="M15 -1L31 21" stroke="white" stroke-opacity="0.6" stroke-width="2" />
  </g>
  <defs>
    <clipPath id="clip0_5712_931">
      <rect width="24" height="20" fill="white" />
    </clipPath>
  </defs>
</svg>`;

const starSvg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.77896 7.04424L8.20737 7.30205L7.77896 7.04424C7.74825 7.09528 7.69814 7.13168 7.6401 7.14512L1.64005 8.53475C0.744185 8.74223 0.391202 9.82861 0.994014 10.523L5.03136 15.174C5.07041 15.219 5.08955 15.2778 5.0844 15.3373L4.55189 21.4731C4.47238 22.3892 5.39651 23.0606 6.24323 22.7019L11.9142 20.2994C11.969 20.2761 12.031 20.2761 12.0858 20.2994L17.7568 22.7019C18.6035 23.0606 19.5276 22.3892 19.4481 21.4731L18.9156 15.3373C18.9104 15.2778 18.9296 15.219 18.9686 15.174L23.006 10.523C23.6088 9.8286 23.2558 8.74223 22.3599 8.53475L16.3599 7.14512C16.3019 7.13168 16.2518 7.09528 16.221 7.04424L13.0453 1.76726C12.5711 0.979361 11.4289 0.979362 10.9547 1.76726L7.77896 7.04424Z"
    fill="#FFBB00" stroke="#CC9600" />
</svg>`;

let cachedFlagImage = null;
let cachedHashureImage = null;
let cachedStarImage = null;
let isImageLoading = { flag: false, hashure: false, star: false };

function createCachedImage(svg, type) {
  return new Promise((resolve) => {
    if (
      (type === "flag" && cachedFlagImage) ||
      (type === "hashure" && cachedHashureImage) ||
      (type === "star" && cachedStarImage)
    ) {
      resolve(
        type === "flag"
          ? cachedFlagImage
          : type === "hashure"
            ? cachedHashureImage
            : cachedStarImage
      );
      return;
    }

    if (isImageLoading[type]) {
      resolve(null);
      return;
    }

    isImageLoading[type] = true;
    const img = new Image();
    const blob = new Blob([svg], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);

    img.onload = () => {
      if (type === "flag") {
        cachedFlagImage = img;
      } else if (type === "hashure") {
        cachedHashureImage = img;
      } else {
        cachedStarImage = img;
      }
      URL.revokeObjectURL(url);
      isImageLoading[type] = false;
      resolve(img);
    };

    img.src = url;
  });
}

Chart.plugins.register({
  beforeDatasetsDraw: function (chart) {
    const lineChartId = document.getElementById('line-chart')
    if (!lineChartId) {
      return
    }
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    const totalItems = chart.data.labels.length;

    if (cachedStarImage && totalItems >= 12) {
      const lastXPos = chart.scales["x-axis-0"].getPixelForTick(11);
      ctx.drawImage(cachedStarImage, lastXPos - 12, chartArea.top - 12, 24, 24);
    }

  },

  beforeDraw: async function (chart) {
    const lineChartId = document.getElementById('line-chart')
    if (!lineChartId) {
      return
    }
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    const labels = chart.data.labels;
    const totalItems = labels.length;
    const linePositions = [];
    const shouldShowStar = totalItems >= 12;

    if (totalItems >= 12) {
      linePositions.push(3);
      linePositions.push(7);
      linePositions.push(11);
    }
    else if (totalItems >= 8) {
      linePositions.push(3);
      linePositions.push(7);
    }
    else if (totalItems >= 4) {
      linePositions.push(3);
    }

    const imagesToLoad = [
      createCachedImage(flagSvg, "flag"),
      createCachedImage(hashureSvg, "hashure"),
    ];

    if (shouldShowStar) {
      imagesToLoad.push(createCachedImage(starSvg, "star"));
    }

    if (!cachedFlagImage || !cachedHashureImage || (shouldShowStar && !cachedStarImage)) {
      await Promise.all(imagesToLoad);
    }

    linePositions.forEach((index) => {
      const xPos = chart.scales["x-axis-0"].getPixelForTick(index);
      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = "rgba(178, 48, 102, 0.37)";
      ctx.lineWidth = 20;
      ctx.moveTo(xPos, chartArea.bottom);
      ctx.lineTo(xPos, chartArea.top);
      ctx.stroke();
      ctx.restore();
    });

    linePositions.forEach((index, arrayIndex) => {
      const xPos = chart.scales["x-axis-0"].getPixelForTick(index);
      const totalHeight = chartArea.bottom - chartArea.top;

      if (cachedFlagImage) {
        const flagHeight = 20;
        const flagY = 2;

        ctx.drawImage(cachedFlagImage, xPos - 10, flagY, 20, flagHeight);

        ctx.save();
        ctx.fillStyle = "#666666";
        ctx.font = "700 12px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "top";
        ctx.fillText(`${arrayIndex + 1}º Ano`, xPos, flagY + flagHeight + 5);
        ctx.restore();
      }

      if (cachedHashureImage && (!shouldShowStar || index !== 11)) {
        let startPercent, endPercent;
        if (index === 3) {
          startPercent = 0.2;
          endPercent = 0.25;
        } else if (index === 7) {
          startPercent = 0.5;
          endPercent = 0.55;
        }

        if (startPercent !== undefined && endPercent !== undefined) {
          const hashureY = chartArea.bottom - totalHeight * endPercent;
          const rangeHeight = (endPercent - startPercent) * totalHeight;

          ctx.drawImage(
            cachedHashureImage,
            xPos - 10,
            hashureY,
            20,
            rangeHeight
          );
        }
      }
    });
  },
});

export default {
  name: "UiLineChart",
  extends: Line,
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        width: "100%",
        elements: {
          line: {
            tension: 0,
            borderColor: "#B23066",
            borderWidth: 1,
          },
          point: {
            radius: 1,
            borderColor: "#B23066",
            backgroundColor: "#B23066",
            borderWidth: 10,
            hitRadius: 10,
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 100,
                callback: (value) => {
                  return `${value}%`;
                },
                padding: 12,
              },
            },
          ],
          xAxes: [
            {
              position: "top",
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 20,
                callback: () => {
                  return "";
                },
              },
            },
            {
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 20,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const datasetIndex = tooltipItem.datasetIndex;
              const dataIndex = tooltipItem.index;
              const yValue = tooltipItem.yLabel;
              const isLastPoint =
                dataIndex === data.datasets[datasetIndex].data.length - 1;
              return isLastPoint ? `Período ativo: ${yValue}%` : `${yValue}%`;
            },
            title: (tooltipItem, data) => {
              return data.labels[tooltipItem[0].index];
            },
          },
        },
      },
    };
  },
  mounted() {
    const chartData = {
      labels: this.data.labels || [],
      datasets: this.data.datasets.map((dataset, datasetIndex) => {
        const modifiedDataset = { ...dataset };

        if (datasetIndex === this.data.datasets.length - 1) {
          modifiedDataset.pointBackgroundColor = "#B23066";
          modifiedDataset.pointBorderColor = "#B23066";
          modifiedDataset.pointRadius = 0.1;
        }

        return {
          ...modifiedDataset,
          borderColor: "#B23066",
          backgroundColor: "transparent",
          fill: false,
        };
      }),
    };

    const finalOptions = {
      ...this.defaultOptions,
      ...this.options,
    };

    this.renderChart(chartData, finalOptions);
  },
};
</script>

<style scoped>
canvas {
  background-color: transparent !important;
}
</style>
