<template>
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5712_931)">
      <rect width="24" height="20" fill="#B23066" />
      <path d="M-1 -1L15 21" stroke="white" stroke-opacity="0.6" stroke-width="2" />
      <path d="M-9 -1L7 21" stroke="white" stroke-opacity="0.6" stroke-width="2" />
      <path d="M7 -1L23 21" stroke="white" stroke-opacity="0.6" stroke-width="2" />
      <path d="M15 -1L31 21" stroke="white" stroke-opacity="0.6" stroke-width="2" />
    </g>
    <defs>
      <clipPath id="clip0_5712_931">
        <rect width="24" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>

<style lang="sass">

</style>
